import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import AnchorLink from "react-anchor-link-smooth-scroll"
import {
  LazyLoadImage as img,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import _ from "lodash"

// svg decorations
// import "@components/svg-decorations/svg-decorations.scss"
import WaveTop from "@components/svg-decorations/waveTop"
import WaveBottom from "@components/svg-decorations/waveBottom"
import TopBottomCurve from "@components/svg-decorations/topBottomCurve"
import CornerAccent from "@components/svg-decorations/cornerAccent"

//case study swiper
import CaseStudyScroll from "@components/swipers/caseStudyScroll"

//leadroll steps
import LeadRollSteps from "@components/leadrollStepsAccordion/leadrollSteps"

//faq accordion
import FaqAccordion from "@components/faqAccordion/faqAccordion"

//thesis
import Step1Question from "@components/thesisQuestions/step1q"
import Step2Question from "@components/thesisQuestions/step2q"
import Step3Question from "@components/thesisQuestions/step3q"
import Step4Question from "@components/thesisQuestions/step4q"
import Step5Question from "@components/thesisQuestions/step5q"
import Step6Question from "@components/thesisQuestions/step6q"
import Step7Question from "@components/thesisQuestions/step7q"
import QAoffer from "@components/thesisQuestions/qa-offer"
//animation
import { Fade, Zoom, Slide } from "react-reveal"

class ProcessFlow extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    // const posts = this.props.data.allSanityPost.edges
    const caseStudy = this.props.data.allSanityCaseStudy.edges
    // const headShots = this.props.data.allSanityHeadshot.edges
    const objVideo = this.props.data.allSanityObjectionVideos.edges
    const wonLeads = this.props.data.allSanityLeads.edges

    console.log(wonLeads)

    const bottomStudy = caseStudy.filter(
      item => item.node.caseStudyPosition === "bottom"
    )

    const faqs =
      this.props.data &&
      this.props.data.sanityCompanyInfo &&
      this.props.data.sanityCompanyInfo.faq
    const partners = _.chunk(wonLeads, 37)

    console.log(partners)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Process Flow" description={siteDescription} />

        <div
          className="p-relative own-sell-section text-center"
          style={{ padding: "150px 0 100px" }}
        >
          <h3 className="display-4 thesis-activity-name font-weight-bold text-white mb-5">
            This is for you if
            <br />
            <em>you own or sell for a...</em>
          </h3>
          <div className="container">
            <div className="row align-items-start pb-2">
              <div className="col-md-6 text-center">
                {" "}
                <Slide left>
                  <div className="blob-1">
                    <img
                      className="img-fluid mb-3"
                      src={require("@images/one.svg")}
                      alt=""
                      style={{ width: "200px" }}
                    />
                  </div>
                  <h2 className="text-white mb-4 text-secondary-gradient">
                    High Ticket Service Business...
                  </h2>
                </Slide>
              </div>
              <div className="col-md-6 text-center">
                {" "}
                <Slide right>
                  <img
                    className="img-fluid mb-3"
                    src={require("@images/two.svg")}
                    alt=""
                    style={{ width: "200px" }}
                  />
                  <h2 className="text-white mb-4 text-secondary-gradient">
                    Or a Funded SaaS Startup...
                  </h2>
                </Slide>
              </div>
            </div>
            <h3 className="mb-5 text-center text-white">
              With at least one solid case study, <br />
              and a client lifetime value of $10k or more per deal, <br />
              and you’re looking to double <br />
              or triple your ARR in the next 12 months.
              <br />
            </h3>
          </div>
        </div>

        <div
          className="about-section p-relative"
          id="who-is-this-for"
          style={{ overflow: "hidden" }}
        >
          <div className="container">
            <CornerAccent />
            <Fade up>
              <h2 className="text-primary-gradient display-5 mb-4">
                This training is for you if...
              </h2>
              <h5 className="mb-5">
                You’re a High-Ticket Service Business, or Funded SaaS Startup
                with at least one solid case study, and a client lifetime value
                of $10k or more per deal, and you’re looking to double or triple
                your ARR in the next 12 months.
              </h5>
            </Fade>

            <Fade>
              <>
                <h3 className="text-primary-gradient mb-4">Desires</h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If you know that you could hit your growth goals if you
                        could only pack your calendar with qualified
                        appointments (guaranteed) -- and you’re ready to grow,
                        and take your sales to $1M - $10M ARR, raise your next
                        round, or get scalable sales traction that leaves your
                        competition in the dust -- this is for you.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        Our mission is to empower high-ticket businesses like
                        yours with the qualified appointments you need to scale
                        a truly fantastic organization and make a positive
                        impact on your target market.
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            </Fade>

            <Fade>
              <>
                <h3 className="text-primary-gradient mb-4">
                  Problems and Emotions
                </h3>
                <p className="mt-3">Tell me if this sounds familiar?...</p>

                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        You get referrals, but there’s no predictability. Maybe
                        you’ve tried paid ads, but they just didn’t produce ROI.
                        You might have even hired sales reps, but they spend
                        most of their time searching for new deals (with
                        little-to-no process behind them) rather than actually
                        closing...
                      </li>
                      <li>
                        Perhaps you’ve even tried outbound prospecting in some
                        small way via LinkedIn or email, but your experiment
                        didn’t check all the boxes needed for radical success,
                        and the activity generated was nothing more than empty
                        carbs...
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If you’ve tried digital selling, but you’re feeling
                        stuck and overwhelmed with the ever changing world of
                        marketing tactics...
                      </li>
                      <li>
                        And you know you need a truly powerful sales process
                        that can drive predictable growth in your business...
                      </li>
                      <li>
                        And you’re ready to take your growth to the next level
                        and start making a real impact on your market at
                        scale...
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  If that’s been your story, press into this moment, and take
                  the time to absorb what we’re about to share.
                </p>
                <p>
                  Right now, if you’ve dabbled with outbound sales, there’s a
                  very strong chance you’re struggling with low email open
                  rates, low response rates, less than 1% demo booking rates,
                  poor data, misinformation, no clear lead process or project to
                  plan reporting -- and you’re likely feeling extremely
                  frustrated and disheartened by reps or cheap services that
                  don’t produce results. You may even be angry at the fact that
                  if nothing changes, you simply won’t hit your growth goals...
                </p>
              </>
            </Fade>

            <Fade>
              <>
                <h3 className="text-primary-gradient mt-5 mb-4">
                  Put Your Fears to Rest
                </h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If deep down you fear that you won’t find the right help
                        needed to pack your team’s sales calendar soon, and
                        you’re worried that if you’re not able to light up your
                        pipeline -- your business might remain stagnant... We
                        want to put those fears to rest.
                      </li>
                      <li>
                        Sales appointments can be predictably controlled -- and
                        we can prove it.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        It doesn’t require years (or even months) of trial and
                        error before yielding results.
                      </li>
                      <li>
                        It doesn’t require a large sales team if you don’t have
                        one yet. And it is possible to know with reasonable
                        certainty whether or not you can make outbound selling
                        work, before you invest a single dime through predictive
                        math (which we’re happy to provide to you for free).
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            </Fade>

            <Fade>
              <>
                <h3 className="text-primary-gradient mb-4">
                  You Can’t Afford to Fail Again
                </h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If you’ve tried outbound selling on your own and it
                        didn’t work, it’s not your fault -- or even your team’s
                        fault. If you tried cold emailing and you got crickets
                        -- fact is, 99% of businesses and sales teams completely
                        fail at outbound because they don’t have a proven
                        roadmap for success, and it’s way more complex than most
                        realize.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        Cold outbound selling is not easy, and turning ice-cold
                        prospects into warm qualified appointments on the
                        calendar day-in and day-out is a science that they don’t
                        teach in school. Let alone the fact that technology is
                        advancing at an incredible rate, and the tactics you
                        need to succeed right now, this year, weren’t even
                        possible a few years ago (in some cases, they weren’t
                        possible a few months ago -- and we innovate on a near
                        weekly basis to stay ahead of the curve).
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            </Fade>
            <Fade>
              <>
                <h3 className="text-primary-gradient mb-4">
                  You’re Right to Be Suspicious
                </h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If you suspected that you actually don’t need more
                        leads, and that what you actually need is guaranteed
                        appointments on the calendar, you’d be 100% correct.
                      </li>
                      <li>
                        You’d also be right in questioning whether or not the
                        people showing you how to prospect on YouTube or in
                        cheap digital courses really know what they are talking
                        about. All too often the experts over-simplify, the
                        agencies under-deliver, and deep down you actually knew
                        from the start that it wasn’t as easy as spending a few
                        hundred bucks on a cheap automated solution and landing
                        $20,000 deals...{" "}
                      </li>
                      <li>
                        As a matter of fact, if you had a hunch that platforms
                        like LinkedIn and cold email can actually work with the
                        right high-touch approach, you’d be absolutely right.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If you also have enough experience to know that cold
                        email is not dead, then you are right there as well (and
                        we have the results to prove it).{" "}
                      </li>
                      <li>
                        Cold prospecting is, however, freaking hard work, and
                        enriching the data alone can sometimes cost thousands of
                        dollars per month per rep. There are no shortcuts, but
                        extreme ROI can be achieved.
                      </li>
                      <li>
                        You know as well as we do that nothing in sales has
                        actually changed, and that your market will only ever
                        buy from those they know, like, and trust -- and it does
                        take extreme effort and amazing follow-up to bring
                        highly affluent leads across the finish-line.
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            </Fade>

            <Fade>
              <>
                <h3 className="text-primary-gradient mb-4">
                  We’ve Been There...
                </h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        If you’ve experienced the talking heads who are telling
                        you how to market, but their advice hasn’t made an ounce
                        of difference for your sales… we’ve been there too.
                      </li>
                      <li>
                        Not only that, but if you’ve been shopping around for
                        lead gen providers, and you had a sneaking suspicion
                        that the spammy low-cost, no commitment, shady looking
                        businesses out there that promise things like “Done For
                        You LinkedIn Outreach” or some automated approach is too
                        good to be true, and could get you banned or blacklisted
                        -- you’d be right there as well.{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        Unfortunately, there is no quick fix or growth hack that
                        can ramp your sales the way you truly need, and you
                        might even be able to save a buck… but we’ve seen this
                        story play out hundreds of times…
                      </li>
                      <li>
                        If you don’t have an ironclad full outbound process that
                        guarantees appointments on the calendar -- not only will
                        you be wasting your money on mindless activity that
                        doesn’t actually produce, but all these ‘agencies’
                        actually do is plug your profile into some risky
                        low-cost automation tool, the spammy messages they write
                        get ignored, and what seemed like a short cut ends up
                        never moving the needle.
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            </Fade>

            <Fade>
              <>
                <h3 className="text-primary-gradient mb-4">We Can Prove it</h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        LeadRoll was originally founded by our CEO Tyler Kemp
                        who’s helped build several 7+ figure businesses, and has
                        been helping sales professionals and startups scale
                        their sales for nearly a decade.
                      </li>
                      <li>
                        He originally started in the Mortgage space and realized
                        that relying on referrals alone was not sustainable, and
                        that most businesses that typically rely on
                        relationships to make a sale, didn’t have a predictable
                        way to drive business.
                      </li>
                      <li>
                        He went on to discover cutting edge methods of social
                        selling and advertising, and along with a single loan
                        officer his team was generating $120 Million per year in
                        loan volume, with 20% growth year over year.
                      </li>
                      <li>
                        Word got out about his success, and Sales Professionals
                        across the country wanted to know the secret sauce to
                        that level of growth, so he traveled state to state
                        teaching the exact tactics needed to drive more
                        predictable topline revenue.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="checked list-unstyled mt-md-4">
                      <li>
                        After a while he went on to serve on the marketing
                        advisory board of a $16 Billion company, and eventually
                        he transitioned to the SaaS space -- helping startups
                        like Homebot grow from $60K per month, to over $200K per
                        month in only 8 months.
                      </li>
                      <li>
                        Today, LeadRoll generates more than 100,000 leads per
                        month for customers all across the globe, saving the
                        clients we work with around 500+ hours of prospecting
                        labor per month per sales person, and driving 10x-20x
                        yield of employed sales teams, at a fraction of the cost
                        -- while adding a layer of predictability and certainty
                        to our client’s topline revenue.
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            </Fade>
          </div>
        </div>

        <div className="background-story-section">
          <TopBottomCurve />
          <div className="container">
            <div className="row justify-content-center align-items-start mb-5">
              <div className="col-md-3 text-center mt-5">
                <Fade up>
                  <img
                    alt=""
                    className="round-image-shadow shadow-dark img-fluid"
                    src={require("@images/tyler-kemp.jpg")}
                    width="200px"
                  />
                  <div className="pt-3" style={{ lineHeight: "1.5" }}>
                    <h5 className="text-white">Tyler Kemp</h5>
                    <p className="text-white">
                      Social Selling Expert | Speaker | CEO @ LeadRoll
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="col-md-7">
                <Slide right>
                  <h2 className="text-secondary-gradient">
                    LeadRoll was originally founded by our CEO, Tyler Kemp
                  </h2>
                  <p className="text-white pt-3">
                    Helped Build Several 7+ Figure Businesses ● National speaker
                    on business growth strategy and marketing process...
                  </p>
                  <p className="text-white">
                    Founder of several international marketing agencies…
                  </p>
                  <p className="text-white">
                    Sales Leader, Podcaste Speaker, and Denver Startup Leader...
                  </p>
                  {/* <ol className="text-white">
                    <li>A lack of consistent follow-up.</li>
                    <li>A lack of intimacy and humanity in the sale.</li>
                    <li>A lack of relevance and effective positioning.</li>
                    <li>A lack of proper leverage and efficient process.</li>
                  </ol> */}
                  <p className="text-white">
                    Helped hundreds of companies implement this system over the
                    past decade - Companies from incubators like Y Combinator,
                    Techstars, and 500 Startups
                  </p>
                  <p className="text-white">
                    Word got out about his success, and Sales Professionals
                    across the country wanted to know the secret sauce to that
                    level of growth, so he traveled state to state teaching the
                    exact tactics needed to drive more predictable topline
                    revenue
                  </p>
                  <p className="text-white">
                    After a while, he went on to serve on the marketing advisory
                    board of a $16 Billion company, and eventually, he
                    transitioned to the SaaS space -- helping startups like
                    Homebot grow from $60K per month to over $200K per month in
                    only 8 months.
                  </p>
                  <p className="text-white">
                    Today, LeadRoll generates more than 100,000 leads per month
                    for customers all across the globe, saving the clients we
                    work with around 500+ hours of prospecting labor per month
                    per salesperson and driving 10x-20x yield of employed sales
                    teams, at a fraction of the cost -- while adding a layer of
                    predictability and certainty to our client’s topline
                    revenue.
                  </p>
                  <p className="text-white">You'll be glad you did.</p>
                </Slide>
              </div>
            </div>
            {/* <div className="row justify-content-center align-items-start">
              <div className="col-md-3 text-center order-md-2 mt-5">
                <Fade up>
                  <img
                    alt=""
                    className="round-image-shadow shadow-dark img-fluid"
                    src={require("@images/ian-tappan.jpg")}
                    width="200px"
                  />
                  <div className="pt-3" style={{ lineHeight: "1.5" }}>
                    <h5 className="text-white">Ian Tappan</h5>
                    <p className="text-white">Chief Operating Officer</p>
                  </div>
                </Fade>
              </div>
              <div className="col-md-7 order-md-1">
                <Slide right>
                  <p className="text-white pt-3">
                    Implementing sales outreach that *actually* converts into
                    scalable sales is a major problem.
                  </p>
                  <p className="text-white">
                    There are four main reasons most brands are completely
                    failing at digital outreach:
                  </p>
                  <ol className="text-white">
                    <li>A lack of consistent follow-up.</li>
                    <li>A lack of intimacy and humanity in the sale.</li>
                    <li>A lack of relevance and effective positioning.</li>
                    <li>A lack of proper leverage and efficient process.</li>
                  </ol>
                  <p className="text-white">
                    You NEED to be Frequent, Intimate, and Relevant in order to
                    turn cold leads into sales, but you must also be Efficient.
                  </p>
                  <p className="text-white">
                    At LeadRoll we help sales teams solve the problem of
                    predictable sales at a fraction of the cost.
                  </p>
                  <p className="text-white">
                    It's a fractional sales service that's designed to get you
                    moving quickly, and uses a proven methodology for
                    ridiculously profitable appointment setting and sales
                    success, and low customer acquisition cost.
                  </p>
                  <p className="text-white">
                    To learn more, check our the resources at
                    https://leadroll.co.
                  </p>
                  <p className="text-white">You'll be glad you did.</p>
                </Slide>
              </div>
            </div> */}
          </div>
          <div className="blob-bg"></div>
          <div className="blob-bg"></div>
        </div>

        <div
          className="text-center"
          style={{ paddingTop: "100px", paddingBottom: "125px" }}
        >
          <div className="container">
            <Fade down>
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <h2 className="text-uppercase text-primary-gradient">
                    Introducing Our Thesis
                  </h2>
                  <h4>
                    The Scientific Method To Highly Profitable Sales Outreach
                    That Dramatically Lowers Customer Acquisition Cost (CAC)
                  </h4>
                </div>
              </div>
            </Fade>
          </div>
        </div>

        <div className="thesis-steps">
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-secondary-gradient"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 1
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Validate the Profitability of Cold Outreach With Your
                    Financial Model
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg d-block mx-auto"
                      src={require("@images/ROI_Calculation_Image.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3">
                      Stop Playing Outbound Lottery
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        Contrary to popular entrepreneur rhetoric, “do-overs”
                        are incredibly costly - due to the compounding effect of
                        money.
                      </li>
                      <li>
                        Someone wise once said: “Take the long-road, it’s
                        faster” -- and in your case, that “long-road” is MATH.
                      </li>
                      <li>
                        Validating the estimated ROI of your marketing channel
                        will help ensure a hyper-profitable unit case before you
                        step on the outbound sales pedals.
                      </li>
                      <li>
                        Anything less than demonstrable ROI adds risk to the
                        equation -- and jeopardizes your profits, regardless of
                        whether or not the investment is high or on the lower
                        end. Opportunity cost alone is enough to justify the
                        time it takes to properly ensure you don’t scale a leaky
                        bucket.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>You hear about some new way to market.</li>
                          <li>
                            You find the cheapest way possible to execute.
                          </li>
                          <li>
                            You’d spend time and money trying to figure it out
                            or test it.
                          </li>
                          <li>You see activity, but no sales...</li>
                          <li>
                            Expectations start to set in, but they may not align
                            with true profitability of your unit case.
                          </li>
                          <li>
                            No one has run the true math with reasonable
                            expectations and proof to back it up before
                            starting.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            The scope of what’s actually needed is greater than
                            you thought or realized.
                          </li>
                          <li>
                            The low-budget company you outsourced to doesn’t
                            deliver any results that move the needle.
                          </li>
                          <li>Time is wasted. Money is wasted.</li>
                          <li>
                            The activity is an equivalent of an empty calorie.
                            It’s busy, it’s filling, but doesn’t produce
                            positive results.
                          </li>
                          <li>
                            A true test of the marketing channel is never
                            actually done.
                          </li>
                          <li>
                            Expectations remain out of line. All parties are
                            disappointed. ROI is never achieved.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You follow a proven path to success with
                            high-quality service providers.
                          </li>
                          <li>
                            You define expectations, goals, and tactics needed
                            to reach those goals in detail.
                          </li>
                          <li>
                            You validate the ROI math before beginning, and
                            eliminate most of the risk.
                          </li>
                          <li>
                            Activity is based on metrics and executed with
                            precision and within budget.
                          </li>
                          <li>
                            ROI is calculated monthly and quarterly against
                            goals.
                          </li>
                          <li>
                            Analytics and predictive math inform the decisions.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Following a proven path eliminates trial and error.
                            Costs are lower by doing it right the first time.
                          </li>
                          <li>
                            Expectations are in alignment. Tactics are in
                            alignment with broader strategy.
                          </li>
                          <li>
                            Lowered risk leaves room for proper testing and
                            time.
                          </li>
                          <li>
                            Success is benchmarked, all activities are a means
                            to an end.
                          </li>
                          <li>
                            Appointments are booked. SDRs spend their time
                            closing deals.
                          </li>
                          <li>Sales are made. Goals are hit.</li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-secondary-gradient text-uppercase">
                      When you measure something, it becomes far easier to
                      control.
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        The reality of marketing is, the tactics you implement
                        are just a means to an end, and the activity itself is
                        meaningless if it doesn’t lead to the desired result
                        defined by your strategy.
                      </li>
                      <li>
                        By understanding the activities needed for success, and
                        measuring actual performance against your plan and ROI
                        numbers, you’re able to know when you’re on track to
                        achieve your desired end result.
                      </li>
                      <li>
                        Outbound sales can be controlled and measured, and by
                        knowing exactly how many prospects need to be
                        identified, how many of them need to be reached, how
                        many of them need to respond, how many of those
                        responses need to be positive, and how much follow-up
                        will need to be done to get these formerly cold leads on
                        the calendar -- you can control these metrics, and with
                        the right tactics book appointments, and close deals in
                        line with your projections.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center text-primary-gradient pt-5 pb-4">
                  Or Continue Reading
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step1Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-white"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 2
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Craft A Laser-Focused Lead List
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg d-block mx-auto"
                      src={require("@images/Sales_Navigator.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase">
                      You’re About to Discover...
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        The most critical step in any successful outbound sales
                        initiative is to compile a hyper targeted leads list.
                        <br />
                        You’ll also want to acquire LinkedIn Sales Navigator,
                        which un-caps your selling potential on LinkedIn, and
                        allows access to advanced lead filtering. There are
                        other ways to find leads, but trust us when we say,
                        Sales Navigator is an absolute must, and it only costs
                        about $79/m per user if you’re not on the team plan.
                      </li>
                      <li>
                        But don’t be fooled… Not every search is created equal!
                        Never trust your lead search to amateurs, as it’s the
                        corner-stone of your entire campaign.
                      </li>
                      <li>
                        It’s essential to utilize advanced search tactics
                        including boolean search terms, exclusions, and proper
                        keyword searches to narrow down your prospect list
                        exclusively to demographically qualified prospects.
                      </li>
                      <li>
                        The beauty of this approaches that you’ll be able to
                        identify your prospects by name and verify list quality
                        before ever starting your outreach.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            In the past, lead targeting was a complete guessing
                            game.
                          </li>
                          <li>
                            If you were running ads, you had no idea who they
                            were actually going to.
                          </li>
                          <li>
                            If you bought lists, you had no way of knowing if it
                            was valid.
                          </li>
                          <li>
                            Lead targeting was a catch-all with limited filters
                            and often high margin of error.
                          </li>
                          <li>
                            Sales Navigator is confusing, so you’d have to rely
                            on sketchy LinkedIn companies to help target, and
                            you never achieve qualified fit because the list is
                            never quite right and the low-budget companies never
                            review, change, test, or modify the search once it’s
                            built...
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            This meant you just had to ‘trust the process’...
                          </li>
                          <li>
                            Large budgets end up being spent just to send ads to
                            an unqualified list.
                          </li>
                          <li>
                            You never could read the names on the list, see
                            their profiles, check their websites, or validate
                            their lead quality.
                          </li>
                          <li>
                            The uncomfortable margin of error in the lead list
                            means that any campaign you run to those leads is
                            completely pointless.
                          </li>
                          <li>
                            You end up with a stale list that was never done
                            correctly, and you end up missing the mark on your
                            outbound campaign because of it.
                          </li>
                          <li>
                            You end up with a campaign foundation made of
                            sand...
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            Not only can you target based on Sales Navigator’s
                            advanced filters, but there are other means of
                            acquiring amazing lead lists as well.
                          </li>
                          <li>
                            This means you can target demographically (title,
                            keywords, industry, etc.) and also by technology
                            used -- depending on what works best.
                          </li>
                          <li>
                            You now start with a list that you can vet, change,
                            sift-through, and approve. Every name, every
                            profile, every domain, is now visible.
                          </li>
                          <li>
                            Your list can be updated as needed and is never
                            stale or irrelevant.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>New doors are opened for targeting…</li>
                          <li>
                            You’re able to hit markets that aren’t typically hit
                            with prospecting.
                          </li>
                          <li>
                            You’re left with an amazing list that is full of
                            demographically qualified prospects that simply need
                            to be prospected.
                          </li>
                          <li>Alignment is maintained.</li>
                          <li>Lead quality is never a concern.</li>
                          <li>
                            Maximum flexibility means that you have the freedom
                            to change your targeting along with the changing
                            needs of your business, and never sacrifice speed or
                            quality.
                          </li>
                          <li>
                            Your campaign is built on a foundation of steel.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase">
                      Amazing lists lead to qualified prospects
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      {/* <li>
                        Connecting the dots is simple: amazing lists lead to
                        qualified prospects.
                      </li> */}
                      <li>
                        By putting in the time up-front to ensure that your lead
                        list is correct, and not cutting corners in terms of
                        list creation and options, you’re left with a primed
                        pump and a list of prospects that you can put your offer
                        in front of.
                      </li>
                      <li>
                        It’s fast, efficient, and lays the foundation for your
                        entire outbound campaign.
                        <br />
                        The importance of creating a superior list that is above
                        reproach can’t be understated.
                        <br />
                        You must get this right to succeed, and it can be done.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <h2 className="text-center text-white pt-5 pb-4">
                  Or Continue Reading to Discover...
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step2Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-secondary-gradient"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 3
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Lay The Outbound Foundation
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg d-block mx-auto"
                      src={require("@images/Thesis-3.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3">
                      The Hidden Enemy of Every Outbound Sales Initiative
                    </h6>
                    <p className="text-left">
                      It’s the spam box! If you’re not extremely careful, not
                      only will the vast majority of your outbound emails end up
                      in spam, but you risk your domain becoming blacklisted.
                    </p>
                    <p className="text-left">
                      In order to properly protect yourself, several key
                      elements need to be in place, especially the following:
                    </p>
                    <ul className="list-unstyled checked text-left">
                      <li>A separate burner domain...</li>
                      <li>A unique outbound email for each rep...</li>
                      <li>Proper domain warmup and engagement...</li>
                      <li>
                        Flawless implementation of DMARC, DKIM, SPF records…
                      </li>
                      <li>Active weekly spam monitoring…</li>
                      <li>
                        Campaign based spam fixing when you do land in spam…
                      </li>
                      <li>
                        Campaign based spam fixing when you do land in spam…
                      </li>
                    </ul>
                    <p className="text-left">
                      When this is done correctly, it’s possible to achieve
                      &#60;0.05% spam rate over time -- even in an ice cold
                      outbound campaign, giving your initiative superhero level
                      effectiveness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You’d be tempted to think you can use a typical drip
                            system like ActiveCampaign, MailChimp, GetResponse,
                            or Pardot…
                          </li>
                          <li>
                            Even if you’re savvy enough to realize you need to
                            send with your own SPF reputation, the domain itself
                            isn’t properly prepared.
                          </li>
                          <li>
                            DKIM, SPF, DMARK, and BIMI are all left undone -- or
                            hours and hours are spent trying to figure out how
                            to get proper domain validation done, with no
                            guarantee of success.
                          </li>
                          <li>
                            So you start with your typical domain or a new
                            domain that was never properly warmed.
                          </li>
                          <li>
                            You start to send canned emails, and wonder why no
                            one is responding…
                          </li>
                          <li>No monitoring is done… No spam fixing…</li>
                          <li>
                            You set it and forget it, letting the ‘automation’
                            do the work...
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Your autoresponder system bans you because you’re
                            sending it to a list that didn’t ‘opt-in’, and cold
                            email isn’t allowed.
                          </li>
                          <li>
                            You start using your own SPF authority, but fail to
                            set things up correctly, which immediately marks you
                            as a spammer in the eyes of email providers like
                            google.
                          </li>
                          <li>
                            Emails are sent, but 80% or more of them are landing
                            in spam -- and nothing about them was personalized
                            to the recipient.
                          </li>
                          <li>
                            Your domain reputation gets ruined, your new (or
                            old) domain becomes blacklisted…
                          </li>
                          <li>
                            You think to yourself that outbound emailing just
                            doesn’t work.
                          </li>
                          <li>No sales have been generated.</li>
                          <li>Time and money are wasted.</li>
                          <li>Google now thinks you’re a spammer.</li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You start with a clear knowledge of what needs to be
                            in place before you start using email as a
                            prospecting channel.
                          </li>
                          <li>
                            A new domain is purchased, set up, warmed, and
                            prepared with the proper authentication.
                          </li>
                          <li>
                            A new email account is provisioned, the calendar is
                            synced with your primary inbox, forwarding prepared,
                            and you start sending emails that get opened and
                            responded to in proper cadence to warm the email up.
                          </li>
                          <li>
                            Proper spam monitoring is put in place, and your
                            exact campaign gets positive engagement.
                          </li>
                          <li>
                            Your reputation steadily increases in the eyes of
                            email providers.
                          </li>
                          <li>Proper email limits are maintained.</li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            With proper care and activity under the account,
                            you’re able to begin a campaign the right way!
                          </li>
                          <li>
                            Google recognizes you as a legitimate sender, and
                            your emails land in the inbox.
                          </li>
                          <li>
                            Your response rate on cold emails goes up
                            dramatically when your prospects realize that the
                            email was customized exactly for them.
                          </li>
                          <li>
                            If you do land in spam, you know about it, and know
                            exactly how to get yourself out.
                          </li>
                          <li>Reputation is maintained.</li>
                          <li>Open rate tops the leader boards.</li>
                          <li>Response rate is way above average.</li>
                          <li>
                            Calendar meetings sync effortlessly across multiple
                            calendars.
                          </li>
                          <li>You’re ready for serious scaling…</li>
                          <li>
                            You’re now reaching 4x as many prospects as the
                            competition (who isn’t even able to figure out the
                            wrong way, let alone the right way).
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-secondary-gradient text-uppercase">
                      Never Build an Outbound System on a Weak Foundation
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        Cold email is a science, and there are far more ways to
                        get it wrong then there are ways to get it right.
                      </li>
                      <li>
                        There’s a true technical barrier to effective
                        prospecting at this level, but it is possible to get
                        right!
                      </li>
                      <li>
                        When your domain is properly prepared, and your emails
                        are warmed up, it’s like oil in the engine -- and it
                        allows for seamless outbound sales without massive
                        delivery issues that plague 99.9% of outbound emailers.
                      </li>
                      <li>
                        Think that might give you an edge? The answer is a
                        massive YES, the edge of a lifetime!
                      </li>
                      <li>
                        If you can solve for delivery and domain reputation,
                        you’ll find that your campaign is so much more powerful
                        than you thought possible. More messages are opened and
                        read, because more are actually delivered… The math is
                        simple.
                      </li>
                      <li>
                        It’s what gives your message a chance, and when combined
                        with the proper lead list and an amazing campaign, it
                        can lead to a truly scalable sales system the likes of
                        which most businesses only dream of having.
                      </li>
                      <li>
                        In short, creating the proper outbound foundation sets
                        you up with a sales process that can be scaled to the
                        moon, all without falling prey to the many spam traps
                        that lie in wait for the unprepared.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center text-primary-gradient pt-5 pb-4">
                  Or Continue Reading to Discover...
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step3Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-secondary-gradient"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 4
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Create a Highly Relevant Outreach Message Sequence
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg mx-auto d-none"
                      src={require("@images/Thesis-3.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center text-white">
                <div className="col-6">
                  <img
                    alt=""
                    className="img-fluid shadow-lg mx-auto mb-5"
                    src={require("@images/step4-figure1.jpg")}
                  />
                  <h5>
                    <span style={{ color: "#41b9fa" }}>Figure 1:</span> A
                    message with no relevance and delivered from a profile
                    without perceived status will not lead to sales
                    conversations.
                  </h5>
                  <ul className="list-unstyled checked text-left">
                    <li>
                      <strong>
                        {" "}
                        Broadcasting messages to your prospects that are spammy,
                        inauthentic, or irrelevant will result in apathy
                      </strong>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  {" "}
                  <img
                    alt=""
                    className="img-fluid shadow-lg mx-auto mb-5"
                    src={require("@images/step4-figure2.jpg")}
                  />
                  <h5>
                    <span style={{ color: "#41b9fa" }}>Figure 2:</span>{" "}
                    <strong>A highly relevant message</strong> that bridges the
                    status delta between you and your prospect will result in{" "}
                    <em>resonance</em>.
                  </h5>
                  <ul className="list-unstyled checked text-left">
                    <li>
                      <strong>
                        {" "}
                        Broadcasting these types of messages get prospects
                        engaged and excited to speak with you.
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3">
                      The Backbone of Outbound Success
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        A message with no relevance and delivered from a profile
                        without perceived status will not lead to sales
                        conversations. Period.
                      </li>
                      <li>
                        This is why most people think platforms like LinkedIn or
                        Cold Email don’t work (the thought is laughable for
                        those who know what they’re doing). Your message
                        matters, and broadcasting a campaign to your prospects
                        that is spammy, inauthentic, or irrelevant will result
                        in nothing but apathy.
                      </li>
                      <li>
                        However, a highly relevant message that bridges the
                        status delta between you and your prospect will result
                        in true resonance and authority. Broadcasting those
                        types of messages get prospects engaged, and excited to
                        speak with you.
                      </li>
                      <li>
                        Every email must be customized with an appropriate first
                        line that is hand-crafted for the prospect. This is
                        significant work when you’re reaching out to 65-75
                        people per day (at least that’s what we do), but it’s
                        imperative to engage the high-ticket buyer and achieve
                        the kind of know, like trust, that actually moves the
                        needle.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            Marketing and manipulation mean the same thing as
                            advice gets taken from old-school “marketing gurus”.
                          </li>
                          <li>
                            Outbound campaigns are hastily written, sometimes
                            they’re written by english speakers, and get spammed
                            to the masses, qualified prospect or not…
                          </li>
                          <li>
                            Automation is used to cut corners at the cost of
                            human touch.
                          </li>
                          <li>
                            Often a low-quality discount company is commissioned
                            to write the outreach messages and send automated
                            spam with your name on it.
                          </li>
                          <li>It doesn’t work, no sales are made.</li>
                          <li>
                            You never try cold emailing or LinkedIn outreach
                            again because you think it “doesn’t work”.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Your highly affluent audience sees right through it,
                            and a bridge is now burned.
                          </li>
                          <li>
                            Platforms become crowded and basically ruined by
                            irrelevant sales garbage (which is exactly what
                            happened to direct mail and cold calling).
                          </li>
                          <li>
                            Automated messages are completely and entirely
                            ignored, because you clearly didn’t actually write
                            it.
                          </li>
                          <li>
                            Money is wasted (again) by trying to take the easy
                            path, at the cost of quality.
                          </li>
                          <li>Your reputation takes further hits.</li>
                          <li>
                            You end up worse than you started, due to the
                            compounding effect of capital, opportunity cost, and
                            the high price of reputation management.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You lead with transformation, not manipulation, and
                            you ditch any advice from manipulative and scammy
                            specialists.
                          </li>
                          <li>
                            You choose to do it right from the start, and invest
                            in a finely-tuned omnichannel campaign message.
                          </li>
                          <li>
                            First lines are written for every single prospect
                            with a verified email, every single day.
                          </li>
                          <li>
                            Cheap widgets and shortcuts are skipped entirely,
                            even though they seem appealing.
                          </li>
                          <li>
                            Appointments are set, prospects are surprised at the
                            personal touch.
                          </li>
                          <li>Trust is built, sales are made.</li>
                          <li>
                            You’ve started your client relationship off on a
                            really powerful foot.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Finally you feel good about marketing, almost as
                            though you have a moral imperative to help others
                            with your service, because you truly believe in the
                            results you can deliver.
                          </li>
                          <li>
                            You’re able to cut through the noise and set
                            yourself apart from the scam artists out there
                            trying to manipulate rather than serve.
                          </li>
                          <li>
                            Every single message is relevant, and your prospect
                            knows that it was written only for them.
                          </li>
                          <li>
                            You end up elated that, by doing it right, your
                            audience now sees you as an authority in the space.
                          </li>
                          <li>
                            Your sales calls go way smoother than usual, as
                            they’re still happy you took the time to write them
                            something personal and meaningful.
                          </li>
                          <li>
                            Business flows like you always dreamed it could.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-secondary-gradient text-uppercase">
                      Relevance is Backbone of Outbound Sales
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        By crafting a campaign that is hyper-relevant and
                        uniquely personalized, not only do your response rates
                        soar to absurd rates, but even the negative replies
                        respect you for having taken time to do something
                        different.
                      </li>
                      <li>
                        The qualitative difference is tremendous, with just one
                        small extra step, and every appointment you book has a
                        higher chance of actually converting, because you’ve
                        already established that you do things differently, and
                        don’t just take the easy road that yields nothing but
                        clutter in our inbox.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center text-white pt-5 pb-4">
                  Or Continue Reading to Discover…
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step4Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-secondary-gradient"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 5
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Perform Daily Outbound Outreach & Ironclad Follow Up
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center d-none">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg d-block mx-auto"
                      src={require("@images/Thesis-3.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3">
                      The Fortune is in the Follow-Up
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        As amazing as it may seem, cold outbound prospecting
                        doesn’t actually work in every situation. There are pros
                        and cons to this kind of approach.
                      </li>
                      <li>
                        Building an outbound initiative in your organization
                        does come with several pros. It’s fast to set up, easy
                        to duplicate across your sales team, and pretty easy to
                        measure your ROI on the and money invested --
                        appointments speak for themselves.
                      </li>
                      <li>
                        However, there are some cons to outbound that are worth
                        taking into consideration. Implementing cold sales can
                        be quite difficult to scale without proper guidance,
                        there are just too many nuances and complexities between
                        each step. It’s also extremely time intensive. We
                        consider ourselves highly skilled in terms of
                        delegation, team building, and systematic leverage, yet
                        even with all that, there is no way to truly automate
                        the process -- not if you’re doing it right. In no way
                        can you set-it and forget-it with these kinds of
                        campaigns… They’re a full-time job.
                      </li>
                      <li>
                        There are only a few use-cases where it actually makes
                        sense:
                        <ol>
                          <li>
                            When you’re testing out new offers you’d like to
                            validate, and make your first few sales.
                          </li>
                          <li>
                            When you know exactly who you’re targeting, and
                            really want their attention.
                          </li>
                          <li>
                            When you know exactly who you’re targeting, and
                            really want their attention.
                          </li>
                        </ol>
                      </li>
                    </ul>
                    <p className="text-left">
                      When this is done correctly, it’s possible to achieve
                      &#60;0.05% spam rate over time -- even in an ice cold
                      outbound campaign, giving your initiative superhero level
                      effectiveness.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3 mb-4">
                      Further, there are only a few use-cases where it actually
                      makes sense:
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        When you’re testing out new offers you’d like to
                        validate and make your first few sales.
                      </li>
                      <li>
                        When you know exactly who you’re targeting, and really
                        want their attention.
                      </li>
                      <li>
                        When you are selling tickets with a lifetime gross
                        contribution of $10k or more (the higher, the better).
                      </li>

                      {/* <img
                        alt=""
                        className="img-fluid shadow-lg d-block mx-auto my-5"
                        src={require("@images/step5-chart.jpg")}
                        style={{ padding: "40px", backgroundColor: "#fff" }}
                      /> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>You say to yourself, “how hard can it be?”...</li>
                          <li>
                            You and your sales reps decide to give outbound a
                            try, and you hit the pavement!
                          </li>
                          <li>
                            After about a week, your team finds it hard to stay
                            awake as you reach out to prospects that start to
                            feel like nothing more than numbers…
                          </li>
                          <li>
                            At most you connect with someone on LinkedIn, and
                            send them a single message.
                          </li>
                          <li>After message one, pure silence…</li>
                          <li>
                            Prospecting cools off, consistency is lost, you
                            realize you can’t scale what you’ve been doing.
                          </li>
                          <li>Once again, outbound “didn’t work”.</li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>It’s harder than you thought.</li>
                          <li>
                            Good intentions turn into frustration as you realize
                            how little follow-up actually happens.
                          </li>
                          <li>
                            The pure monotony of cold outreach makes you feel
                            like you’d rather be eating Tide pods on Tik Tok
                            with your gen Z nephews and nieces.
                          </li>
                          <li>Follow-up is a fortune you never tap into</li>
                          <li>
                            You’re forced to “churn and burn” prospects, and
                            decide to lower the amount of outreach you perform
                            each day in favor of going deep not wide (an
                            excuse).
                          </li>
                          <li>
                            You can’t scale the thing you just tried, nor would
                            you wish that laborious execution on any human you
                            genuinely care about.
                          </li>
                          <li>
                            You go back to relying on referrals, because
                            generating cold deals is too much work.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You know ahead of time that cold outbound only
                            really shines if you have a high-ticket offer, and
                            you offer high value to justify it.
                          </li>
                          <li>
                            You realize that scaling outbound is complex, and
                            you bring on appropriate help to implement across
                            your sales team.
                          </li>
                          <li>
                            Follow-up is planned, measured, controlled,
                            monitored, and leveraged.
                          </li>
                          <li>
                            The role of prospecting is separated from the role
                            of closing.
                          </li>
                          <li>
                            Your closers no longer have to hunt for new deals,
                            as they’re booked with appointments.
                          </li>
                          <li>
                            Appointment setters operate completely autonomously
                            from closers.
                          </li>
                          <li>
                            Deals flow like water, and you’re able to go both
                            deep and wide with your prospects.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            You make more revenue, and your clients receive the
                            level of service and results they truly need to
                            succeed.
                          </li>
                          <li>
                            The process gets scaled effortlessly throughout your
                            company as sales gets handled, and your real
                            challenge is now service delivery.
                          </li>
                          <li>
                            Nothing gets left undone, and you’re able to finally
                            realize the truth of the phrase “the fortune is in
                            the follow-up”.
                          </li>
                          <li>
                            Your closers are making more money than ever before
                            since you’ve separated the roles.
                          </li>
                          <li>
                            Your cash flow skyrockets since your closers are
                            bonused on deals, and you’re cost for prospecting
                            remains low and highly profitable (especially if
                            you’re working with LeadRoll).
                          </li>
                          <li>No lead gets left behind.</li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-secondary-gradient text-uppercase">
                      Follow-up is a Mission-Critical Component
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        With ironclad follow-up finally being executed the way
                        you hoped it would be, your organization starts to
                        operate like a well oiled machine. The statistics that
                        report sales reps spending up to 40% of their time
                        looking for somebody to call (Inside Sales), with 44% of
                        reps giving up after one follow-up, no longer applies to
                        you.
                        <br />
                        In short, low-ticket doesn’t play nice with outbound,
                        but if you provide serious value and your prospects are
                        willing to pay for it, outbound is by far the most
                        powerful channel there is for generating new deals --
                        and with the help of companies like LeadRoll, outbound
                        can be scaled company-wide.
                        <br />
                        It works, and once you choose to capitalize on it, you
                        simply duplicate it to drive volume.
                      </li>
                      <li>
                        It works, and once you choose to capitalize on it, you
                        simply duplicate it to drive volume.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center text-primary-gradient pt-5 pb-4">
                  Or Continue to Discover...
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step5Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-secondary-gradient"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 6
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Clearly Report & Track Qualified Interest -- And Iterate
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg mx-auto d-none"
                      src={require("@images/Thesis-3.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3">
                      It’s Impossible to Measure Something You Don’t Track
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        Understanding performance is the key to any sales
                        initiative, and the only possible way to recognize
                        effectiveness is through tracking, reporting, and
                        measuring.
                      </li>
                      <li>
                        It all begins with capturing demographic data and
                        enhancing your lead intelligence with emails and phone
                        numbers to fuel the backbone of outreach.
                      </li>
                      <li>
                        Next, it’s mission-critical to capture every exchange
                        and assess whether each response is positive, neutral,
                        or negative, and leverage what you’ve learned to
                        follow-up like an iron-clad pro.
                      </li>
                      <li>
                        Lastly, you perform split-tests and iterate monthly
                        until you have a battle-tested campaign driving leads
                        straight into your calendar like clock-work.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div
                        className="thesis-old"
                        style={{ borderBottomRightRadius: "35px" }}
                      >
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            Reporting is a time-consuming task, and you find
                            yourself thinking, “I’ll get back to it later.”
                          </li>
                          <li>
                            Yet, you quickly realize going back is even more
                            work than you assumed and it accumulates into one
                            massive headache of confusion
                          </li>
                          <li>
                            Data gets lost for good, responses aren’t properly
                            tracked, initiations aren’t helping you learn how to
                            build fully-functional campaigns.
                          </li>
                          <li>
                            Somehow you feel secure thinking all your messages
                            are safely stored in LinkedIn, and in your email
                            inboxes.
                          </li>
                          <li>
                            You stop following up because there’s no structure
                            guiding long-term nurture, and otherwise qualified
                            leads become MIA.
                          </li>
                          <li>
                            You’re still confused after months of work, why
                            aren’t appointments filling up your calendar as you
                            projected?
                          </li>
                          <li>
                            You still have no idea how to turn this around and
                            little do you know, without a proper reporting
                            system in place, it’s only going to get worse.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Important data you need in order to book qualified
                            sales calls winds up slipping right through your
                            fingers, never to be seen again, as a result,
                            reporting stays on the back burner, never truly
                            becoming a priority.
                          </li>
                          <li>
                            Like many outbound efforts, you thought you could
                            get away with “winging it”... if only for now, yet
                            it’s one mistake that is secretly sabotaging your
                            campaign, even when everything else is executed with
                            precision.
                          </li>
                          <li>
                            You thought you could skip the small, unimportant
                            details, and never produce a quality campaign as a
                            result, but you’re left with a sinking feeling in
                            your gut, you can’t place where it went wrong.
                          </li>
                          <li>
                            You start wondering why you’re not getting as many
                            appointments as you projected, but you don’t know if
                            your success metrics are above average, or abysmal,
                            because you can’t track what you haven’t measured.
                          </li>
                          <li>
                            Each month the realization sinks in more and more,
                            you don’t have a clue who is qualified, and neither
                            do your salespeople because if it’s not reported, it
                            doesn’t count.
                          </li>
                          <li>
                            Your campaign is a lack-luster crusade that simply
                            doesn’t have the bravado or virtuoso to book
                            qualified sales calls.
                          </li>
                          <li>
                            Leads continue to fall like sand through an
                            hourglass as you slowly lose time and energy.
                          </li>
                          <li>
                            Outbound becomes a cash-burn, and the stress,
                            headache, and fear of failure once again, have only
                            just begun...
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            Finally, you understand it's not enough to create
                            whirlwinds of activity. Connection requests, emails,
                            and voicemails are now monitored and reported along
                            with every relevant stat.
                          </li>
                          <li>
                            By tracking every response, you know you're doing
                            outbound right from the start. No lead intelligence
                            is deemed unworthy, metrics surround all activity,
                            and it's clear what works and what doesn’t.{" "}
                          </li>
                          <li>
                            You realize you need proper infrastructure to
                            enhance your tracking and reporting, and you know
                            you don’t need to do it alone.
                          </li>
                          <li>
                            Building the infrastructure is no easy task, but
                            your level of pure effectiveness and efficiency
                            skyrockets, and you now have the insight needed to
                            optimize for increased performance.
                          </li>
                          <li>
                            You know reporting prevents leads from slipping
                            between the cracks, and your campaign will fall
                            short without it.
                          </li>
                          <li>
                            Stressful outbound becomes a thing of the past,
                            considering LeadRoll as the solution is exciting to
                            you
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Your new follow-up infrastructure possesses the rare
                            ability to overflow your calendar with qualified
                            sales calls.
                          </li>
                          <li>
                            You obtain stellar outbound campaigns, reports allow
                            you to make necessary adjustments for continued
                            growth, and your follow-up responses are consistent.
                          </li>
                          <li>
                            You take game-changing swings toward growth --
                            you’re no longer wondering whether or not qualified
                            sales calls will meet your projections, and your
                            success metrics illuminate a victory as calls line
                            your calendar
                          </li>
                          <li>
                            The best part is, you know with certainty every
                            appointment is a premium quality, and your sales
                            team doesn’t have to worry about talking to the
                            right person anymore.
                          </li>
                          <li>
                            Every response gets a follow-up, and your rock-solid
                            system ensures you no longer abandon solid leads in
                            the process.
                          </li>
                          <li>
                            You sit back and relax as your calendar magically
                            fills up to the brim with qualified sales
                            opportunities excited to hear your solution.
                          </li>
                          <li>
                            LeadRoll single-handedly removes the
                            unpredictability that once plagued your entire
                            outbound efforts and gives you back the freedom to
                            enjoy doing what you do best.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-secondary-gradient text-uppercase">
                      Conclusions/ Interpretations Regarding Point 4
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        By installing a superior system to precisely track,
                        measure, and report your lead data, you’re consistently
                        booking predictable sales appointments, finally, you
                        begin to meet your projected goals -- or even surpass
                        them.
                      </li>
                      <li>
                        Without a reputable process for tracking and reporting,
                        there’s simply no way to eliminate the stress and
                        headache associated with outbound metrics, because what
                        is not tracked is not measured -- simply put, it’s as if
                        your hard work never happened.
                      </li>
                      <li>
                        In short, outbound is a powerful tool when the right
                        effort is allocated to building and maintaining proper
                        tracking and reporting infrastructure -- and by
                        automating this entire process with LeadRoll, generating
                        predictable appointments just became your new magic
                        wand.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center text-white pt-5 pb-4">
                  Or Continue Reading to Discover...
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step6Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="thesis-step-section">
            <WaveTop />
            <WaveBottom />
            <div className="container">
              <Slide down>
                <div className="thesis-step-title-wrapper">
                  <span
                    className="font-weight-bold text-secondary-gradient"
                    style={{ fontSize: "25px" }}
                  >
                    STEP 7
                  </span>
                  <h3 className="display-4 thesis-activity-name font-weight-bold">
                    Bring Qualified Leads Down Funnel To The Point Of Sale
                  </h3>
                </div>
              </Slide>

              <div className="row justify-content-center d-none">
                <div className="col-10">
                  <div className="thesis-image-wrapper">
                    <CornerAccent />
                    <img
                      alt=""
                      className="img-fluid shadow-lg d-block mx-auto"
                      src={require("@images/Thesis-3.jpg")}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-uppercase mt-3">
                      It’s No Longer a Guessing Game
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        One of the most well-known and powerful sayings in
                        business is the classic adage “the fortune is in the
                        follow-up”.
                      </li>
                      <li>
                        Before a lead will convert, they need to buy in to your
                        solution, and more often than not -- it will take
                        follow-up to get MQLs to book a call on your/your team’s
                        calendar.
                      </li>
                      <li>
                        Everything up to this point should have been designed to
                        que up a call with the right kind of lead, and when well
                        executed, you should be booking 15-25 sales calls per
                        month -- if not more.
                      </li>
                      {/* <li>
                        A message with no relevance and delivered from a profile
                        without perceived status will not lead to sales
                        conversations. Period.
                      </li>
                      <li>
                        This is why most people think platforms like LinkedIn or
                        Cold Email don’t work (the thought is laughable for
                        those who know what they’re doing). Your message
                        matters, and broadcasting a campaign to your prospects
                        that is spammy, inauthentic, or irrelevant will result
                        in nothing but apathy.
                      </li>
                      <li>
                        However, a highly relevant message that bridges the
                        status delta between you and your prospect will result
                        in true resonance and authority.
                      </li>
                      <li>
                        Broadcasting those types of messages get prospects
                        engaged, and excited to speak with you
                      </li> */}
                    </ul>
                    <p className="text-left">
                      When this is done correctly, it’s possible to achieve
                      &#60;0.05% spam rate over time -- even in an ice cold
                      outbound campaign, giving your initiative superhero level
                      effectiveness.
                    </p>
                    {/* 
                    <img
                      alt=""
                      className="img-fluid shadow-lg d-block mx-auto my-5"
                      src={require("@images/step7-chart.jpg")}
                      style={{ padding: "40px", backgroundColor: "#fff" }}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row align-items-start no-gutters py-5">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old wAY
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You play a guessing game and hope that by some
                            miracle you happen to say the right thing to the
                            right person but you never get the response you want
                            and don’t know how to move forward in the follow-up.
                          </li>
                          <li>
                            You feel like you lost leads that should be talking
                            to you and you don’t know where in the process you
                            lost them. You continue to second-guess yourself and
                            drop the lead when it starts out great.
                          </li>
                          <li>
                            You wing the follow up like a last-minute speech and
                            the prospect feels the impersonal, sales like
                            rhetoric frustrating them beyond measure.
                          </li>
                          <li>
                            You don’t have a system in place to create and
                            automate a reminder to help mitigate the inevitable.
                            People often get busy living their lives, they get
                            distracted and forget, and you haven’t thought about
                            how to overcome the hurdle.
                          </li>
                          <li>
                            You feel like creating a real follow-up technique is
                            just too complex to implement and even if you wanted
                            to devise one, you don’t know where to start or
                            what’s effective.
                          </li>
                          <li>
                            You push forward, single-handedly outbound
                            prospecting for yourself, thinking it’s the only
                            solution available.{" "}
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Old RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            Your follow-up efforts aren’t on par with the actual
                            workload required to effectively fuel the outbound
                            success you’re aiming for. You waste time and money
                            on a barren calendar you can’t seem to fill.
                          </li>
                          <li>
                            You respond to every message, but find it extremely
                            difficult to navigate the conversation to a call
                            without sounding like a salesman or worse, a
                            spam-artist.
                          </li>
                          <li>
                            It upsets you when far too many leads get lost in
                            the shuffle once you finally get to schedule a call.
                            You often wonder why it can’t be easier and if it’s
                            even worth it anymore.
                          </li>
                          <li>
                            You get frustrated and wonder why when a call
                            finally gets booked but someone doesn’t show up.
                          </li>
                          <li>
                            All the unknown variables are stacking up against
                            you and you can’t figure out why all your efforts
                            don’t lead to more booked calls.
                          </li>
                          <li>
                            After numerous headaches, outbound seems way too
                            difficult to continue, and you wonder if moving
                            forward is the best idea.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New Way
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You know you can’t afford to guess at outbound
                            follow-up anymore and stop playing the lottery with
                            your energy and resources. You leverage LeadRoll
                            expertise to automate follow-up for you.
                          </li>
                          <li>
                            You finally find relief from all the stress
                            accumulating over time and trust all your leads are
                            getting the attention they want in order to
                            transform into booked sales calls.{" "}
                          </li>
                          <li>
                            Every follow-up is crafted by an expert able to
                            compose personal and effective follow-up responses,
                            you no longer need to second-guess your replies or
                            come up with them on the spot. You only use tested
                            and proven messages in your campaign.
                          </li>
                          <li>
                            Automatic reminders inform you and your prospects
                            when it’s time to get on your call, simply put, you
                            make it lifetimes easier for them to commit their
                            time to your call -- and show up on time.
                          </li>
                          <li>
                            You realize it’s much more effective to use a proven
                            system to follow-up like a vetted pro, instead of
                            trying to learn through the struggles and learning
                            curve all on your own, and that doing so would be
                            incredibly difficult and take years to get right.{" "}
                          </li>
                          <li>
                            You see clearly now that there are ingenious
                            solutions to tackle the difficulties of outbound and
                            are open to implementing them.
                          </li>
                        </ul>
                        <h6 className="text-light-blue text-uppercase mb-3">
                          New RESULT
                        </h6>
                        <ul className="checked list-unstyled">
                          <li>
                            You clearly understand the follow-up isn’t something
                            to play the lottery with, you need to be precise,
                            elegant, and consistent in your message so you begin
                            booking predictable calls your closers are excited
                            to work with -- and they roll in month after month.
                          </li>
                          <li>
                            Finally, a fruitful follow-up system automates the
                            talent of professionals on your behalf, every
                            follow-up message is customized and crafted
                            according to the prospect. With LeadRoll on your
                            side, guessing is permanently removed from the
                            equation.
                          </li>
                          <li>
                            It’s exciting that you know how to free up more
                            time, have less stress, less responsibility, and
                            more sales calls lining your calendar than ever
                            before.
                          </li>
                          <li>
                            You’re happy to discover more qualified appointments
                            actually show up for the call, delighted to know you
                            may be the solution they have been looking for.
                          </li>
                          <li>
                            All the outbound variables stack in your favor after
                            you integrate a done for you, step-by-step follow-up
                            system, you can relax with certainty knowing you
                            have something that really works for you.
                          </li>
                          <li>
                            Outbound is a powerful solution driving reliable
                            results and you feel empowered by the fact you don’t
                            have to do it the hard way anymore.{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center thesis-insight">
                    <h6 className="text-secondary-gradient text-uppercase">
                      You Can Finally Count on Predictable Sales Appointments
                    </h6>
                    <ul className="list-unstyled checked text-left">
                      <li>
                        Now that your follow-up is orchestrated like music and
                        in tune with your prospect, you’re finally able to drive
                        the momentum follow-up requires to book appointments
                        filling your calendar with qualified calls month over
                        month.
                      </li>
                      <li>
                        There are challenges to creating the right follow-up,
                        but when this is done correctly your company has a sharp
                        edge over your competition, combine your sales and
                        closing skills with our outbound processes and you get a
                        massively scalable outbound sales system.
                      </li>
                      <li>
                        In short, creating the proper follow-up funnel sets you
                        up to fill your calendar and close more sales than ever,
                        and by outsourcing your follow-up to LeadRoll, you don’t
                        lift a finger and save yourself from the difficulty and
                        labor required to make it a recurring success.
                      </li>
                    </ul>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg mb-5"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center d-none">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12 mx-auto mb-5">
                      <p>
                        Now that your follow-up is orchestrated like music and
                        in tune with your prospect, you’re finally able to drive
                        the momentum follow-up requires to book appointments
                        filling your calendar with qualified calls month over
                        month.
                      </p>
                      <p>
                        There are challenges to creating the right follow-up,
                        but when this is done correctly your company has a sharp
                        edge over your competition, combine your sales and
                        closing skills with our outbound processes and you get a
                        massively scalable outbound sales system.
                      </p>
                      <p>
                        In short, creating the proper follow-up funnel sets you
                        up to fill your calendar and close more sales, and by
                        outsourcing your follow-up to LeadRoll, you don’t lift a
                        finger and save yourself from the difficulty and labor
                        required to make it a recurring success.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="thesis-insight">
                        <h5 className="text-primary text-uppercase text-center">
                          With LeadRoll, you essentially install a done-for-you
                          appointment booking machine
                        </h5>
                        <h6 className="text-uppercase mb-3 text-center">
                          Do you see that if you:
                        </h6>
                        <ul className="list-unstyled checked text-left">
                          <li>
                            Treat the follow-up like a guessing game, it’s
                            really like playing the lottery?
                          </li>
                          <li>
                            Implementing a superior follow-up system is critical
                            if you want to be guaranteed predictable sales calls
                            flood your calendar month over month.
                          </li>
                          <li>
                            But hiring a company like LeadRoll automatically
                            gives you the keys to reliable and tested processes
                            that are proven to work?
                          </li>
                          <li>
                            Ignore the hidden variables and they’ll quickly
                            weigh you down, and make booking predictable
                            appointments even more difficult to schedule?
                          </li>
                          <li>
                            But by outsourcing to LeadRoll, getting prospects
                            down the funnel and onto your calendar is the
                            easiest way to stress-free follow-up?
                          </li>
                          <li>
                            You will be able to infinitely scale your sales and
                            predictably flood your sales team’s calendar with
                            qualified appointments?{" "}
                          </li>

                          {/* <li>
                        With ironclad follow-up finally being executed the way
                        you hoped it would be, your organization starts to
                        operate like a well oiled machine. The statistics that
                        report sales reps spending up to 40% of their time
                        looking for somebody to call (Inside Sales), with 44% of
                        reps giving up after one follow-up, no longer applies to
                        you.
                        <br />
                        In short, low-ticket doesn’t play nice with outbound,
                        but if you provide serious value and your prospects are
                        willing to pay for it, outbound is by far the most
                        powerful channel there is for generating new deals --
                        and with the help of companies like LeadRoll, outbound
                        can be scaled company-wide.
                        <br />
                        It works, and once you choose to capitalize on it, you
                        simply duplicate it to drive volume.
                      </li> */}
                        </ul>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="thesis-insight">
                        <h5 className="text-primary text-center text-uppercase">
                          You can implement these strategies on your own, but
                          you will likely run into some problems...
                        </h5>

                        <ul className="list-unstyled checked text-left">
                          <li>
                            Creating follow-up messages could take you much
                            longer than necessary.
                          </li>
                          <li>
                            People who don’t fully understand how to create the
                            content will be writing them for you and the quality
                            will be low
                          </li>
                          <li>
                            Message quality will be off and you could get false
                            negatives.?
                          </li>
                          <li>
                            Follow-up is a diligent process that needs a lot of
                            attention, not enough and your leads fall through,
                            losing trust in the process.
                          </li>
                          <li>
                            But by outsourcing to LeadRoll, getting prospects
                            down the funnel and onto your calendar is the
                            easiest way to stress-free follow-up?
                          </li>
                          <li>
                            Getting lost in the shuffle is incredibly easy to
                            do, it’s a full time job managing thousands of
                            follow-up activities, it’s highly likely qualified
                            leads are falling through the cracks.
                          </li>

                          {/* <li>
                        With ironclad follow-up finally being executed the way
                        you hoped it would be, your organization starts to
                        operate like a well oiled machine. The statistics that
                        report sales reps spending up to 40% of their time
                        looking for somebody to call (Inside Sales), with 44% of
                        reps giving up after one follow-up, no longer applies to
                        you.
                        <br />
                        In short, low-ticket doesn’t play nice with outbound,
                        but if you provide serious value and your prospects are
                        willing to pay for it, outbound is by far the most
                        powerful channel there is for generating new deals --
                        and with the help of companies like LeadRoll, outbound
                        can be scaled company-wide.
                        <br />
                        It works, and once you choose to capitalize on it, you
                        simply duplicate it to drive volume.
                      </li> */}
                        </ul>
                      </div>
                    </div>
                    <a
                      href="https://salesprocess.io/quiz"
                      target="_blank"
                      rel="noreferrer"
                      title="Get a Price"
                      className="btn btn-primary btn-lg mx-auto"
                    >
                      Get a Price
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center text-primary-gradient pt-5 pb-4">
                  Or Continue Reading to Discover..
                </h2>
                <div className="mt-3 mb-5">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <Step7Question />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="summary-section">
          <WaveTop />
          <WaveBottom />
          <div className="container">
            <Fade>
              <>
                <h2 className="display-4 text-white text-center">
                  The Science of Outbound Revealed
                </h2>
              </>
            </Fade>
            <div className="row justify-content-center mt-4">
              <div className="col-md-6">
                <div className="text-center thesis-insight">
                  <h6 className="text-secondary-gradient text-uppercase">
                    Do you see that if you:
                  </h6>
                  <ol className="list-unstyled checked text-left text-white">
                    <li>
                      Treat the follow-up like a guessing game, it’s really like
                      playing the lottery?
                    </li>
                    <li>
                      Implementing a superior follow-up system is critical if
                      you want to be guaranteed predictable sales calls flood
                      your calendar month over month.{" "}
                    </li>
                    <li>
                      And creating the perfect down funnel and executing on it
                      is a full-time job full of complex, nuanced operation?
                    </li>
                    <li>
                      But hiring a company like LeadRoll automatically gives you
                      the keys to reliable and tested processes that are proven
                      to work?
                    </li>
                    <li>
                      Ignore the hidden variables and they’ll quickly weigh you
                      down, and make booking predictable appointments even more
                      difficult to schedule?
                    </li>
                    <li>
                      But by outsourcing to LeadRoll, getting prospects down the
                      funnel and onto your calendar is the easiest way to
                      stress-free follow-up?
                    </li>
                    <li>
                      You will be able to infinitely scale your sales and
                      predictably flood your sales team’s calendar with
                      qualified appointments?{" "}
                    </li>
                  </ol>
                  <p className="text-white">
                    With LeadRoll, you essentially install a done-for-you
                    appointment booking machine.
                  </p>
                  <a
                    href="https://salesprocess.io/quiz"
                    target="_blank"
                    rel="noreferrer"
                    title="Get a Price"
                    className="btn btn-primary btn-lg"
                  >
                    Get a Price
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>
        <div className="options-table-section">
          <div className="container">
            <h2 className="text-primary-gradient text-center pb-5 mb-4">
              Here’s Your Options...
            </h2>

            <Zoom>
              <div className="my-4" style={{ position: "relative" }}>
                <CornerAccent />
                <div className="table-responsive table-borderless styled-table">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr className="align-items-center d-flex">
                        <th className="col-md-4 text-center">
                          <span className="h3 d-block">Option 1</span>
                        </th>
                        <th className="col-md-4 text-center">
                          <span className="h3 d-block">
                            Problems with Option 1
                          </span>
                        </th>
                        <th className="col-md-4 text-center">
                          <span className="h3 d-block">Option 2</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="d-flex">
                        <td className="col-md-4 p-4">
                          You can implement these strategies on your own, but
                          you will likely run into some problems.
                        </td>
                        <td className="col-md-4 p-4">
                          <p>
                            Creating follow-up messages could take you much
                            longer than necessary. People who don’t fully
                            understand how to create the content will be writing
                            them for you and the quality will be low. Message
                            quality will be off and you could get false
                            negatives. Follow-up is a diligent process that
                            needs a lot of attention, not enough and your leads
                            fall through, losing trust in the process. Getting
                            lost in the shuffle is incredibly easy to do, it’s a
                            full time job managing thousands of follow-up
                            activities, it’s highly likely qualified leads are
                            falling through the cracks.
                          </p>
                        </td>
                        <td className="col-md-4 p-4">
                          Or Continue Scrolling to Discover How LeadRoll
                          Revolutionizes Outbound Appointment Setting
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Zoom>
            <Zoom>
              <div className="text-center pb-5">
                <a
                  href="https://salesprocess.io/quiz"
                  target="_blank"
                  rel="noreferrer"
                  title="Get a Price"
                  className="btn btn-primary btn-lg btn-red"
                >
                  Get a Price
                </a>
              </div>
            </Zoom>
          </div>
        </div>

        <div className="options-table-section d-none">
          <div className="container">
            <h2 className="text-primary-gradient text-center pb-5 mb-4">
              The Cost Of Implementing Outreach
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <ul className="crossed">
                  <Fade right>
                    <li>
                      If you were to attempt this yourself, it would literally
                      take{" "}
                      <span className="text-danger font-weight-bold">
                        hundreds of hours per month
                      </span>
                      , with no consistency, process, or guarantee of success...
                    </li>
                    <li>
                      If you were to hire an outbound sales team to do just the
                      prospecting <u>without the appointment setting</u> and
                      follow up,{" "}
                      <span className="text-danger font-weight-bold">
                        they would charge you $10,000/mo...
                      </span>
                    </li>
                    <li>
                      If you were to hire a Business Growth Consultant, you
                      would have to pay{" "}
                      <span className="text-danger font-weight-bold">
                        $60,000 to $75,000 per year
                      </span>{" "}
                      with <u>less than a quarter of the output...</u>
                    </li>
                    <li>
                      If you were to pay for LinkedIn paid ads instead, you
                      would have to pay
                      <ul>
                        <li>
                          <span className="text-danger font-weight-bold">
                            $3,000 per month in service fee
                          </span>{" "}
                          at a minimum, plus
                        </li>
                        <li>
                          <span className="text-danger font-weight-bold">
                            $8,000 per month in ad spend
                          </span>{" "}
                          <u>for the clicks alone</u>...
                        </li>
                      </ul>
                      a minimum of{" "}
                      <span className="text-danger font-weight-bold">
                        $11,000 per month
                      </span>
                      , which is{" "}
                      <span className="text-danger font-weight-bold">
                        $132,000 per year
                      </span>
                      .
                    </li>
                  </Fade>
                </ul>
              </div>
            </div>

            <Zoom>
              <div className="my-4" style={{ position: "relative" }}>
                <CornerAccent />
                <div className="table-responsive table-borderless styled-table">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr className="align-items-center d-flex">
                        <th className="col-12 text-center">
                          <span className="h3 d-block">
                            Here's what you get
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          A laser-targeted leads list -- in LinkedIn sales
                          navigator or targeted by tech stack
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          A completely tailored outreach campaign written by our
                          in-house team of experts
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Setup of burner-domain, dedicated outbound email,
                          calendar sync, spam monitoring, and spam fixing
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Monthly omnichannel split tests and unlimited changes
                          on messages that aren’t performing
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Proxied login for enterprise grade account security
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Daily outreach to ±2,000 prospects (±70-per day) per
                          month in LinkedIn, Email, & Voicemail
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          ±20 follow-up touches and follow-ups per lead to get
                          appointments on the calendar
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Advanced performance tracking that includes verified
                          emails, phone numbers, and demographic data
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Advanced response tracking, lead response
                          qualification, and a.i. based current client exclusion
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Up to 80h of manual lead response follow-up, chasing,
                          selling, and nurturing per month
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          A dedicated account manager, and unlimited access to
                          our support team
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="col-1 text-center">
                          <img
                            src={require("@images/001-check.svg")}
                            className="img-fluid"
                            width="30px"
                            alt=""
                          />
                        </td>
                        <td className="col-11">
                          Monthly activity-to-goal reporting, ROI tracking,
                          appointment setting, & end-to-end lead management
                        </td>
                      </tr>
                      {/*<tr className="d-flex">
                        <td className="option-number text-primary-gradient col-1">
                          2
                        </td>
                        <td className="col-md-3">Lorem ipsum sit dolor amet</td>
                        <td className="col-md-3">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </td>
                        <td className="col-md-2 text-center font-weight-bold">
                          $1,000
                        </td>

                        <td className="col-md-3 text-center">Lorem ipsum</td>
                      </tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
            </Zoom>
            <Zoom>
              <div className="text-center pb-5">
                <a
                  href="https://salesprocess.io/quiz"
                  target="_blank"
                  rel="noreferrer"
                  title="Get a Price"
                  className="btn btn-primary btn-lg btn-red"
                >
                  Get a Price
                </a>
              </div>
            </Zoom>
          </div>
        </div>
        <div className="casestudy-section">
          <div className="text-center sticky-top-header2">
            <h2 className="display-4 text-secondary-gradient">
              Again, this is niche who...
            </h2>
          </div>

          <div className="text-center sticky-top3">
            <div className="scroll-down text-center pt-5">
              <AnchorLink
                href="#who-is-this-for"
                offset="0"
                className="text-white"
              >
                <span></span>
                Scroll
              </AnchorLink>
            </div>
          </div>
          <div className="component">
            <LazyLoadComponent>
              <CaseStudyScroll data={bottomStudy} />
            </LazyLoadComponent>
          </div>

          <div className="blob-bg"></div>
        </div>

        <div id="faq-section" className="py-100">
          <div className="container">
            <h2 className="text-center text-primary-gradient pb-5">
              How LeadRoll Helps
            </h2>

            <div
              className="thesis-step-section"
              style={{ background: "none", paddingBottom: "50px" }}
            >
              {" "}
              <div className="row align-items-start no-gutters py-0">
                <div className="col-md-6 mb-4">
                  <Slide left>
                    <div>
                      <div className="thesis-old">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          Get This Done...
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            You pack your calendar in a few weeks instead of
                            months or years.
                          </li>
                          <li>
                            You write your content much faster and at higher
                            quality.
                          </li>
                          <li>
                            You build your prospecting team much faster and skip
                            the stress and headaches associated with doing it
                            yourself.
                          </li>
                          <li>
                            Your messages will just work because they’ve been
                            groomed, tracked and split-tested.
                          </li>
                          <li>
                            You can forget about expensive consultants who are
                            not delivering on promises and let us install a
                            system that really works.
                          </li>
                          <li>
                            Let our talented team orchestrate the entire process
                            so you don’t have to do anything but close the
                            sales.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
                <div className="col-md-6 mb-4">
                  <Slide right>
                    <div>
                      <div className="thesis-new shadow-lg-dark">
                        <h6 className="text-light-blue text-uppercase mb-3">
                          When you work with us...
                        </h6>
                        <ul className="checked list-unstyled pb-5">
                          <li>
                            We install your outbound engine, instead of tryingOr
                            Continue Scrolling to Discover How LeadRoll
                            Revolutionizes Outbound Appointment Setting to build
                            it on your own.
                          </li>
                          <li>
                            You get the proven process that’s helped thousands
                            of companies drive your appointments from cold
                            prospects to warm sales calls, and we control
                            tracking, measuring, and reporting so no lead is
                            left unturned and all your profitability is
                            reported, so you no longer wonder how much you’re
                            accomplishing.
                          </li>
                          <li>
                            Instead of using trial and error and losing out on
                            valuable data that can be used to strengthen your
                            messages.
                          </li>
                          <li>
                            Every reply gets tested, iterated and of course
                            tracked and measured to ensure quality.
                          </li>
                          <li>
                            Finally, you get a rockstar follow-up system that
                            engages down funnel appointment setting, so your
                            calendar automatically fills up with leads, we
                            literally fulfill all outbound requirements so you
                            can continue to focus on what you do best.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-5">
              <LeadRollSteps />
            </div>
          </div>
        </div>
        <div className="summary-section">
          <WaveTop />
          <WaveBottom />
          <div className="container">
            <Fade>
              <>
                <h2 className="display-4 text-white text-center">
                  Or Continue Reading to Discover Our LeadRoll Guarantee
                </h2>
              </>
            </Fade>

            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="table-responsive table-borderless styled-table">
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr className="align-items-center d-flex">
                        <th className="col-md-6 text-center">
                          <span className="h3 d-block">Warning</span>
                        </th>

                        <th className="col-md-6 text-center">
                          <span className="h3 d-block">Guarantee</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="d-flex">
                        <td className="col-md-6 p-4">
                          We can only handle 40 clients per month, so if this
                          interests you, take action.
                        </td>

                        <td className="col-md-6 p-4">
                          Of course everything is backed up by a guarantee. If
                          we don’t generate responses from your target market,
                          we’ll issue a 100% refund. This guarantees that
                          outbound will work as a channel. We also guarantee
                          qualified booked appointments every month, and are
                          willing to put our money where our mouth is.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p className="text-white lead py-5">
                  Click the Link and Book a Call with one of our experts today.
                  On the call we will dive deep into your specific situation and
                  determine an action plan if we can help you.
                </p>
                <Zoom delay={500}>
                  <Link to="/" className="btn btn-primary btn-lg btn-red">
                    Book a Call
                  </Link>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>
        <div className="qa-offer-section p-relative">
          <div className="container">
            <h2 className="text-primary-gradient text-center pb-5">
              Q and A Regarding Offer
            </h2>
            <QAoffer />
          </div>

          {/* <div className="container mt-5 pt-5">
            <div className="row">
              <div className="col-lg-4">
                {" "}
                <h3 className="text-primary-gradient pb-5 mb-4 ">
                  We’ll Determine The Estimated ROI Of Your Unit Case Before We
                  Even Begin
                </h3>
                <a
                  href="https://docs.google.com/spreadsheets/d/1wfpLbyPoJCrZcs6KpNqyW8lWfvchFWzHLO7s0n49260/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  title="Get a Price"
                  className="btn btn-primary btn-lg btn-red"
                >
                  LeadRoll ROI Calculator
                </a>
              </div>
              <div className="col-lg-4">
                <h3 className="text-primary-gradient mb-4 ">
                  LeadRoll Bulletproof Outbound Prospecting Action Flow -- Every
                  Action Executed With Precision
                </h3>
                <p>
                  <strong>
                    We’ve outlined the sales process, step-by-step
                  </strong>{" "}
                  -- and we follow it to a “T”.
                </p>
                <a
                  href="https://drive.google.com/file/d/1h2nhttUXWI4CpxFnvGW5AbTxKx62vju_/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  title="Get a Price"
                  className="btn btn-primary btn-lg btn-red"
                >
                  LeadRoll Action Plan
                </a>
              </div>
              <div className="col-lg-4">
                <h3 className="text-primary-gradient pb-5 mb-4 ">
                  Here’s Exactly How It Works, Step By Step
                </h3>
                <a
                  href="https://docs.google.com/document/d/1C7UnzobKotkn7tPsNNJdCkP3lIn3_ogu6GWPHEh3Wog/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  title="Get a Price"
                  className="btn btn-primary btn-lg btn-red"
                >
                  LeadRoll Client Action Plan
                </a>
              </div>
            </div>
          </div> */}

          <div className="options-table-section">
            <div className="container">
              <h2 className="text-primary-gradient text-center pb-5 mb-4">
                Next Steps -- Complete The Quiz, Get A Price
              </h2>

              <Zoom>
                <div className="my-4" style={{ position: "relative" }}>
                  <CornerAccent />
                  <div className="table-responsive table-borderless styled-table">
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr className="align-items-center d-flex">
                          <th className="col-md-4 text-center">
                            <span className="h3 d-block">Guarantee</span>
                          </th>
                          <th className="col-md-4 text-center">
                            <span className="h3 d-block">Track Record</span>
                          </th>
                          <th className="col-md-4 text-center">
                            <span className="h3 d-block">Next Steps</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="d-flex">
                          <td className="col-md-4 p-4">
                            100% Performance Based. Appointments guaranteed.
                          </td>
                          <td className="col-md-4 p-4">
                            World-class case studies, ~1% dispute rate
                          </td>
                          <td className="col-md-4 p-4">
                            Complete the Quiz. Get a Price. We’ll Do The Math
                            Over The Phone To Ensure ROI. We’ll Eliminate Any
                            Risk In Working With Us.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Zoom>
              <Zoom>
                <img
                  alt=""
                  className="shadow-dark img-fluid mb-5"
                  src={require("@images/track-record.jpg")}
                  width="100%"
                />
                <div className="text-center pb-5">
                  <a
                    href="https://salesprocess.io/quiz"
                    target="_blank"
                    rel="noreferrer"
                    title="Get a Price"
                    className="btn btn-primary btn-lg btn-red"
                  >
                    Get a Price
                  </a>
                </div>
              </Zoom>
            </div>
          </div>
        </div>

        <div className="outreach-section">
          <div className="container">
            {" "}
            <div className="row">
              <div
                className="col-lg-8 mx-auto"
                style={{ backgroundColor: "#fff" }}
              >
                <h2 className="text-primary-gradient pb-5 mb-4">
                  Next Steps -- Complete The Quiz, Get A Price
                </h2>
                <ul className="checked list-unstyled">
                  <li>
                    If you were to attempt this yourself, it would literally
                    take hundreds of hours per month, with no consistency,
                    process, or guarantee of success…
                  </li>
                  <li>
                    If you were to hire an outbound sales team to do just the
                    prospecting without the appointment setting and follow up,
                    they would charge you $10,000/mo...{" "}
                  </li>
                  <li>
                    If you were to hire a Business Growth Consultant, you would
                    have to pay $60,000 to $75,000 per year with less than a
                    quarter of the output...
                  </li>
                  <li>
                    If you were to pay for LinkedIn paid ads instead, you would
                    have to pay $3,000 per month in service fee at a minimum,
                    plus $8,000 per month in ad spend for the clicks alone.{" "}
                    <strong>
                      A minimum of $11,000 per month which is $132,000 per year.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="summary-section">
          <div className="container">
            <Fade>
              <>
                <h2 className="display-4 text-white text-center mb-5">
                  Performance Guarantee
                </h2>
              </>
            </Fade>
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <div className="row">
                  <div className="col-lg-6">
                    {" "}
                    <h3 className="text-white pt-2">Month 1</h3>
                    <p style={{ color: "#fff" }}>
                      Conversations with your market within 30 days or your
                      money back. If we don’t generate replies from your
                      approved leads list in 30 days, we’ll issue a 100% refund.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <h3 className="text-white pt-2">Every Quarter</h3>
                    <p style={{ color: "#fff" }}>
                      We’ll book appointments every single month on your
                      calendar, or we’ll work for free, and you don’t pay. Sales
                      appointments on your sales team’s calendar -- guaranteed.
                    </p>
                  </div>
                </div>

                <Zoom delay={500}>
                  <Link to="/" className="btn btn-primary btn-lg btn-red mt-5">
                    Book a Call
                  </Link>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>

        <div className="benefits-section bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                {" "}
                <h2 className="display-4 text-primary text-center mb-5">
                  The Perfect Outbound Sales Process
                </h2>
                <ul className="checked list-unstyled">
                  <li>
                    We’re going to dive into the mechanics of booking
                    predictable appointments through outbound prospecting.
                  </li>
                  <li>
                    We’ll walk through the exact blueprints we use to crush
                    sales goals and book high-ticket sales appointments month-in
                    and month-out…
                  </li>
                </ul>{" "}
                <img
                  alt=""
                  className="img-fluid mb-5"
                  src={require("@images/outbound-charts.png")}
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row align-items-start no-gutters py-5">
              <div className="col-md-6 mb-4">
                <Slide left>
                  <div>
                    <div className="benefits">
                      <h3 className="text-light-blue text-uppercase mb-3">
                        Here’s How LeadRoll Revolutionizes Your Outbound Sales
                        Initiatives
                      </h3>
                      <ul className="checked list-unstyled">
                        <li>
                          You will be able to pack the calendar in a few weeks
                          instead of months or years.{" "}
                        </li>
                        <li>
                          You will be able to write you content much faster.
                        </li>
                        <li>
                          You will be able to build your prospecting team much
                          faster.
                        </li>
                        <li>Your messages will just work.</li>
                        <li>
                          You can forget about expensive consultants who are not
                          delivering on promises.{" "}
                        </li>
                        <li>
                          It’s a lot easier than doing it on your own. There is
                          also a community of entrepreneurs who will help you.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
              <div className="col-md-6 mb-4">
                <Slide right>
                  <div>
                    <div className="features shadow-lg-dark">
                      <h3 className="text-light-blue text-uppercase mb-3">
                        Features
                      </h3>
                      <ul className="checked list-unstyled pb-5">
                        <li>
                          When you work with us: we give you a step by step
                          action plan to install your LinkedIn prospecting
                          Engine.
                        </li>
                        <li>
                          Instead of navigating this on your own, you can get
                          the exact steps to set this machine up as quickly as
                          possible.{" "}
                        </li>
                        <li>
                          We give you templates and in-depth instructional
                          videos in our library that you can use to train your
                          team.
                        </li>
                        <li>
                          Instead of using the trial and error method to finding
                          a perfect message, you can use our templates and
                          adjust them to your offer.
                        </li>
                        <li>
                          We will work with you one-on-one to help you craft
                          your messages and make the iterations.
                        </li>
                        <li>
                          Instead of testing language patterns yourself, you can
                          run the message by us for review and we can inject our
                          experience to help you get to market-message resonance
                          faster.
                        </li>

                        {/* <li>
                          You know ahead of time that cold outbound only really
                          shines if you have a high-ticket offer, and you offer
                          high value to justify it.
                        </li>
                        <li>
                          You realize that scaling outbound is complex, and you
                          bring on appropriate help to implement across your
                          sales team.
                        </li>
                        <li>
                          Follow-up is planned, measured, controlled, monitored,
                          and leveraged.
                        </li>
                        <li>
                          The role of prospecting is separated from the role of
                          closing.
                        </li>
                        <li>
                          Your closers no longer have to hunt for new deals, as
                          they’re booked with appointments.
                        </li>
                        <li>
                          Appointment setters operate completely autonomously
                          from closers.
                        </li>
                        <li>
                          Deals flow like water, and you’re able to go both deep
                          and wide with your prospects.
                        </li>
                      </ul>
                      <h6 className="text-light-blue text-uppercase mb-3">
                        New RESULT
                      </h6>
                      <ul className="checked list-unstyled">
                        <li>
                          You make more revenue, and your clients receive the
                          level of service and results they truly need to
                          succeed.
                        </li>
                        <li>
                          The process gets scaled effortlessly throughout your
                          company as sales gets handled, and your real challenge
                          is now service delivery.
                        </li>
                        <li>
                          Nothing gets left undone, and you’re able to finally
                          realize the truth of the phrase “the fortune is in the
                          follow-up”.
                        </li>
                        <li>
                          Your closers are making more money than ever before
                          since you’ve separated the roles.
                        </li>
                        <li>
                          Your cash flow skyrockets since your closers are
                          bonused on deals, and you’re cost for prospecting
                          remains low and highly profitable (especially if
                          you’re working with LeadRoll).
                        </li>
                        <li>No lead gets left behind.</li> */}
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            </div>
            {/* <div className="row align-items-center pb-5">
              <div className="col-md-7">
                <Slide right>
                  <p>
                    You will be able to pack the calendar in a few weeks instead
                    of months or years.
                  </p>
                </Slide>
              </div>
              <div className="col-md-5 text-center">
                <Fade>
                  <img
                    alt=""
                    className="img-fluid round-image-shadow"
                    width="200px"
                    src={require("@images/pexels-photo-3184465.jpeg")}
                  />
                </Fade>
              </div>
            </div>
            <div className="row align-items-center pb-5">
              <div className="col-md-7">
                <Slide right>
                  <p>You will be able to write you content much faster.</p>
                </Slide>
              </div>
              <div className="col-md-5 text-center">
                <Fade>
                  <img
                    alt=""
                    className="img-fluid round-image-shadow"
                    width="200px"
                    src={require("@images/pexels-photo-684387.jpeg")}
                  />
                </Fade>
              </div>
            </div>
            <div className="row align-items-center pb-5">
              <div className="col-md-7">
                <Slide right>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </Slide>
              </div>
              <div className="col-md-5 text-center">
                <Fade>
                  <img
                    alt=""
                    className="img-fluid round-image-shadow"
                    width="200px"
                    src={require("@images/pexels-photo-669615.jpeg")}
                  />
                </Fade>
              </div>
            </div> */}
          </div>
        </div>

        <div className="how-it-works-section p-relative">
          <div className="container">
            <h2 className="text-primary-gradient text-center pb-5">
              How It Works
            </h2>
            <div className="row align-items-center pb-5">
              <div className="col-md-6">
                <Slide right>
                  <p className="pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </Slide>
              </div>
              <div className="col-md-6">
                <Fade>
                  <div className="blob-3">
                    <img
                      alt=""
                      className="img-fluid"
                      src={require("@images/svg1.svg")}
                    />
                  </div>
                </Fade>
              </div>
            </div>
            <div className="row align-items-center pb-5">
              <div className="col-md-6 order-2 order-md-1">
                <Fade>
                  <div className="blob-2">
                    <img
                      alt=""
                      className="img-fluid"
                      src={require("@images/svg2.svg")}
                    />
                  </div>
                </Fade>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <Slide left>
                  <p className="pt-3">
                    Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit, sed quia non numquam eius
                    modi tempora incidunt ut labore et dolore magnam aliquam
                    quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
                    exercitationem ullam corporis suscipit laboriosam, nisi ut
                    aliquid ex ea commodi consequatur? Quis autem vel eum iure
                    reprehenderit qui in ea voluptate velit esse quam nihil
                    molestiae consequatur, vel illum qui dolorem eum fugiat quo
                    voluptas nulla pariatur?
                  </p>
                  <p className="pt-3">
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </Slide>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <Slide right>
                  <p className="pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </Slide>
              </div>
              <div className="col-md-6">
                <Fade>
                  <div className="blob-1">
                    <img
                      alt=""
                      className="img-fluid"
                      src={require("@images/svg3.svg")}
                    />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <div className="summary-section">
          <WaveTop />
          <WaveBottom />
          <div className="container">
            <Fade>
              <>
                <h2 className="display-4 text-white text-center">
                  Tap Into The Power Of Outbound Sales
                </h2>
              </>
            </Fade>
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <p className="text-white lead py-5">
                  Schedule a strategy call with our team and we'll help see if
                  an outbound sales strategy will work in your niche.
                </p>
                <Zoom delay={500}>
                  <a
                    href="https://salesprocess.io/quiz"
                    target="_blank"
                    rel="noreferrer"
                    title="Get a Price"
                    className="btn btn-primary btn-lg btn-red"
                  >
                    Get A Price
                  </a>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>
        <div className="faqs-section">
          <div className="container">
            {/* <div className="row justify-content-center text-center">
              <div className="col-md-8">
                <Fade top>
                  <>
                    <h2 className="text-primary-gradient">
                      Still have questions?
                    </h2>
                  </>
                  <p className="lead pt-5 pb-4 text-muted">
                    We have answers. Check out our robust FAQ section or give us
                    a call and we'll see if LeadRoll is right for you.
                  </p>
                  <Zoom delay={4000}>
                    <>
                      <AnchorLink
                        href="#faq-section"
                        offset="80"
                        className="btn btn-primary m-2"
                      >
                        Frequently Asked Questions
                      </AnchorLink>
                      <Link to="/" className="btn btn-primary m-2">
                        Book a Call
                      </Link>
                    </>
                  </Zoom>
                </Fade>
              </div>
            </div> */}
            {/* <div className="pt-100">
              <div className="row justify-content-around">
                <div className="col-md-6 mb-5">
                  <h4 className="text-primary-gradient">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <p className="small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="embed-container shadow-lg-dark">
                    <iframe
                      src="https://player.vimeo.com/video/308469803"
                      title="VSL"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <h4 className="text-primary-gradient">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <p className="small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="embed-container shadow-lg-dark">
                    <iframe
                      src="https://player.vimeo.com/video/308469803"
                      title="VSL"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <h4 className="text-primary-gradient">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <p className="small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="embed-container shadow-lg-dark">
                    <iframe
                      src="https://player.vimeo.com/video/308469803"
                      title="VSL"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <h4 className="text-primary-gradient">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <p className="small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="embed-container shadow-lg-dark">
                    <iframe
                      src="https://player.vimeo.com/video/308469803"
                      title="VSL"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <h4 className="text-primary-gradient">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <p className="small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="embed-container shadow-lg-dark">
                    <iframe
                      src="https://player.vimeo.com/video/308469803"
                      title="VSL"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <h4 className="text-primary-gradient">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <p className="small">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <div className="embed-container shadow-lg-dark">
                    <iframe
                      src="https://player.vimeo.com/video/308469803"
                      title="VSL"
                      frameBorder="0"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="container pt-100"
              style={{ position: "relative", overflow: "hidden" }}
            >
              <div
                className="obj-headline"
                style={{ maxWidth: "600px", margin: "0 auto" }}
              >
                <h2 className="text-primary-gradient text-center">
                  [ Will The LeadRoll Method Work For Me? ]
                </h2>
                <p
                  className="lead text-muted text-center obj-para"
                  style={{ position: "relative" }}
                >
                  Regardless who you are, I wanted to ensure you knew exactly
                  how this would work for you, based on who you are.
                </p>
              </div>

              <div className="pt-100">
                <div className="row justify-content-around">
                  {objVideo.map(({ node }) => (
                    <div className="col-md-6 mb-5">
                      <div
                        className="obj-video"
                        style={{ position: "relative" }}
                      >
                        <h5
                          className="text-center"
                          style={{
                            backgroundColor: "#5526fd",
                            display: "inline",
                            position: "absolute",
                            top: "-1em",
                            left: "50%",
                            transform: "translateX(-50%)",
                            padding: "5px 10px",
                            color: "#fff",
                            fontSize: "17px",
                            width: "70%",
                          }}
                        >
                          {node.title}
                        </h5>
                        <div className="embed-container shadow-lg-dark">
                          <iframe
                            src={node.videoSource}
                            title={node.title}
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div id="faq-section" className="pt-100">
              <h2 className="text-center text-primary-gradient pb-5">
                Frequently Asked Questions
              </h2>
              <div className="row col-md-10 mx-auto">
                <LazyLoadComponent>
                  <FaqAccordion questions={faqs} />
                </LazyLoadComponent>
                <div className="pt-100 text-center mx-auto">
                  <Zoom>
                    <Link to="/" className="btn btn-primary btn-lg">
                      Learn About Our Thesis
                    </Link>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProcessFlow

export const ProcessFlowQuery = graphql`
  query ProcessFlowQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          logo {
            id
            title
            logo {
              alt
              asset {
                id
                fixed(width: 200) {
                  src
                }
              }
            }
          }
          headshots {
            id
            image {
              alt
              asset {
                id
                fixed(width: 200, height: 200) {
                  src
                }
              }
            }
          }
          graphImage {
            alt
            asset {
              fluid {
                src
              }
            }
          }
          state1
          state2
          timeFrame
          caseStudyPosition
        }
      }
    }
    allSanityHeadshot {
      edges {
        node {
          id
          name
          position
          company
          description
          image {
            alt
            asset {
              id
              fixed(width: 200, height: 200) {
                src
              }
            }
          }
        }
      }
    }
    allSanityLeads(
      filter: { publishArea: { eq: "leads won" } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          logo {
            alt
            asset {
              id
              fixed(width: 150) {
                src
              }
            }
          }
        }
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allSanityObjectionVideos(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          videoSource
          title
          publishDate(formatString: "MMM-DD-YYYY")
        }
      }
    }
    sanityCompanyInfo {
      id
      faq {
        _key
        title
        body
      }
    }
  }
`
